<template>
  <div class="product-not-found mx-4">
    <ion-img src="/assets/images/not-found.svg" class="not-found-img" />
    <div
      class="not-found-text mt-3 mb-2"
      v-html="isConnectionIssue ? $t('unable_load') : $t('not_found_order')"
    ></div>
    <div v-if="isConnectionIssue" class="try-different">
      {{ $t('please_make_sure_connected') }}
    </div>
    <div
      v-if="isOrderIdNotFound"
      class="mt-3 view-all-order-btn"
      expand="block"
      fill="clear"
      @click="toOrdersPage"
    >
      {{ $t('view_all_order') }}
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  props: {
    isConnectionIssue: {
      type: Boolean,
      default: false
    },
    isOrderIdNotFound: {
      type: Boolean,
      default: false
    },
    userType: {
      type: String,
      default: ''
    }
  },

  setup(props) {
    const router = useRouter();
    const toOrdersPage = () => {
      router.replace(props.userType === 'b2b' ? '/b2b/order/purchase' : '/sale/order/purchase');
    };
    return {
      toOrdersPage
    };
  }
});
</script>
<style src="../style.scss" lang="scss" scoped></style>
